import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Box,
} from "@mui/material";
import GoogleSignIn from "./googleSignIn";
import axiosInstance, { baseURL } from "./axiosInstance";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post(
        `${baseURL}auth/login/`,
        {
          grant_type: "password",
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      localStorage.setItem("accessToken", res.data.accessToken);
      navigate("/documents");
    } catch (err) {
      setError("Invalid username or password");
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 8, borderRadius: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <img src="/pessoa-logo.png" alt="Icon" style={{ width: 100 }} />
        </Box>
        <form onSubmit={handleLogin}>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Box>
          {error && (
            <Typography color="error" align="center" mb={2}>
              {error}
            </Typography>
          )}
          <Typography mt={1}>
            <a href="/reset">Forgot password?</a>
          </Typography>
          <Button
            sx={{ mt: 2 }}
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
          >
            Login
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              color: "grey.500",
              fontSize: "0.875rem",
            }}
          >
            <Box
              sx={{
                flex: 1,
                borderBottom: "1px dashed",
                borderColor: "grey.300",
              }}
            />
            <Box sx={{ mx: 2 }}>or</Box>
            <Box
              sx={{
                flex: 1,
                borderBottom: "1px dashed",
                borderColor: "grey.300",
              }}
            />
          </Box>
          <Box mt={2}>
            <GoogleSignIn />
          </Box>
        </form>
      </Paper>
      <Typography align="center" mt={2}>
        Don't have an account? <a href="/register">Register here</a>
      </Typography>
      <ToastContainer />
    </Container>
  );
};

export default Login;
