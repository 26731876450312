import React, { useState } from "react";
import {
  TextField,
  Typography,
  Container,
  Paper,
  Box,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance, { baseURL } from "./axiosInstance";
import { toastConfig } from "./constants";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [resetToken, setResetToken] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const steps = ["Send OTP", "Verify OTP", "Reset Password"];

  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return "Password must be at least 8 characters long.";
    }
    if (!hasUppercase) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowercase) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasNumber) {
      return "Password must contain at least one number.";
    }
    if (!hasSpecialChar) {
      return "Password must contain at least one special character.";
    }
    return "";
  };

  // Handle sending OTP
  const handleSendOtp = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(`${baseURL}auth/send-otp`, { email });
      toast.success("OTP sent to your email!", toastConfig);
      setActiveStep(1);
    } catch (err) {
      // toast.error("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle verifying OTP
  const handleVerifyOtp = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.post(`${baseURL}auth/verify-otp`, {
        email,
        otp,
      });
      setResetToken(res.data.token);
      toast.success("OTP verified!", toastConfig);
      setActiveStep(2);
    } catch (err) {
      // toast.error("Invalid or expired OTP.");
    } finally {
      setLoading(false);
    }
  };

  // Handle resetting password
  const handleResetPassword = async () => {
    const passwordValidationError = validatePassword(newPassword);

    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    try {
      setLoading(true);
      await axiosInstance.post(`${baseURL}auth/reset-password`, {
        token: resetToken,
        newPassword,
      });
      toast.success("Password reset successfully!", toastConfig);
      // setActiveStep(0); // Go back to the start for another reset if needed
      navigate("/login");
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 8, borderRadius: 2 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Password Reset
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <Box mt={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSendOtp}
              sx={{ mt: 2 }}
              loading={loading}
            >
              Send OTP
            </LoadingButton>
          </Box>
        )}

        {activeStep === 1 && (
          <Box mt={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleVerifyOtp}
              sx={{ mt: 2 }}
              loading={loading}
            >
              Verify OTP
            </LoadingButton>
          </Box>
        )}

        {activeStep === 2 && (
          <Box mt={3}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              sx={{ mb: 2 }}
            />
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleResetPassword}
              disabled={!!newPassword && newPassword !== confirmPassword}
              loading={loading}
            >
              Reset Password
            </LoadingButton>
            {passwordError && (
              <Typography color="error" align="center" mb={2}>
                {passwordError}
              </Typography>
            )}
          </Box>
        )}

        <ToastContainer />
      </Paper>
    </Container>
  );
};

export default PasswordReset;
