import React, { useState } from "react";
import axiosInstance from "./axiosInstance";
import { Box, IconButton, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { subDocHeight } from "./constants";
import BstLayout from "./BstLayout";
import { CloudUpload, Clear, Download } from "@mui/icons-material";

export default function BstMedia(props) {
  const { subDocument, expand, handlSelectSubDoc, fullScreen } = props;
  const subDocumentId = subDocument._id;
  const isExpanded = expand[subDocumentId];
  const isFullscreen = fullScreen[subDocumentId];
  const [path, setPath] = useState(subDocument.mediaPath);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(path);
  const [loading, setLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);
    const fileUrl = URL.createObjectURL(file);
    setPreviewUrl(fileUrl);
  };

  const handleClearPreview = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    updateDocument();
  };

  const updateDocument = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append("file", selectedFile);
    setLoading(true);
    try {
      const response = await axiosInstance.postForm(
        `/sub-doc/upload/${subDocumentId}`,
        formData
      );
      setPath(response.data.mediaPath);
      console.log("Media saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving media:", error.response.data);
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*, video/*, application/pdf",
  });

  return (
    <BstLayout
      isFullscreen={isFullscreen}
      isExpanded={isExpanded}
      subDocumentId={subDocumentId}
      handleSelectSubDoc={handlSelectSubDoc}
      {...props}
    >
      <Box sx={{ borderTop: "1px solid grey" }}>
        <input {...getInputProps()} />
        {!previewUrl ? (
          <Box
            {...getRootProps()}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              padding: 2,
              cursor: "pointer",
              height: "300px",
              marginBottom: 2,
              borderRadius: "10px",
            }}
          >
            <Typography>
              {isDragActive
                ? "Drop the file here..."
                : "Drag and drop a file here, or click to select"}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: isFullscreen ? "90vh" : subDocHeight,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {selectedFile?.type?.startsWith("image") ||
            (!selectedFile &&
              (previewUrl?.endsWith(".png") ||
                previewUrl?.endsWith(".jpg"))) ? (
              <img
                src={previewUrl}
                alt="Preview"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            ) : selectedFile?.type?.startsWith("video") ||
              (!selectedFile &&
                (previewUrl?.endsWith(".mp4") ||
                  previewUrl?.endsWith(".webm"))) ? (
              <video controls style={{ maxWidth: "100%", maxHeight: "100%" }}>
                <source
                  src={previewUrl}
                  type={selectedFile?.type || "video/mp4"}
                />
                Your browser does not support the video tag.
              </video>
            ) : selectedFile?.type === "application/pdf" ||
              (!selectedFile && previewUrl?.endsWith(".pdf")) ? (
              <embed
                src={previewUrl}
                type="application/pdf"
                width="100%"
                height="100%"
              />
            ) : null}
          </Box>
        )}
        {previewUrl && !isFullscreen && (
          <Box
            sx={{
              mt: 3,
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid #e0e0e0",
              borderRadius: "8px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <IconButton
                color="default"
                onClick={updateDocument}
                disabled={loading}
              >
                <CloudUpload />
              </IconButton>
              <IconButton
                color="default"
                onClick={handleClearPreview}
                disabled={loading}
              >
                <Clear />
              </IconButton>
              {path && (
                <IconButton
                  color="default"
                  component="a"
                  href={path}
                  download
                  target="_blank"
                >
                  <Download />
                </IconButton>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </BstLayout>
  );
}
