import { Box, IconButton, Typography, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axiosInstance from "./axiosInstance";
import CloseIcon from "@mui/icons-material/Close";
import { BstSourceSearchTextField } from "./CustomComponents/muiComponents";

const BstSources = (props) => {
  const { expandSource } = props;
  const [link, setLink] = useState("");
  const [links, setLinks] = useState([]);
  const [filteredLinks, setFilteredLinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { id: documentId } = useParams();

  useEffect(() => {
    const loadSources = async () => {
      try {
        const response = await axiosInstance.get(`/doc/${documentId}/sources`);
        const { data } = response.data;
        setLinks(data);
        setFilteredLinks(data);
      } catch (error) {
        setLinks([]);
        setFilteredLinks([]);
        console.log(error);
      }
    };

    if (documentId) {
      loadSources();
    }
  }, [documentId]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = links.filter(
      (link) =>
        link.url.toLowerCase().includes(lowerCaseQuery) ||
        (link.description &&
          link.description.toLowerCase().includes(lowerCaseQuery))
    );
    setFilteredLinks(filtered);
  }, [searchQuery, links]);

  const fetchLinkPreview = async (url) => {
    try {
      const response = await fetch(
        `https://api.linkpreview.net/?key=${process.env.REACT_APP_API_FETCH_KEY}&q=${url}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch preview");
      }
      const data = await response.json();

      const getFirstTwoSentences = (text) => {
        if (!text) return ""; // Return empty string if description is not provided
        const sentences = text.match(/[^.!?]*[.!?]/g) || []; // Split into sentences
        return sentences.slice(0, 2).join(" ").trim(); // Take the first two sentences
      };

      return {
        url,
        title: data.title,
        description: getFirstTwoSentences(data.description),
        image: data.image,
      };
    } catch {
      return { url, error: true };
    }
  };

  const handleUpdateLinks = async (links) => {
    await axiosInstance.patch(`/doc/${documentId}/sources`, { sources: links });
  };

  const addLink = async (url) => {
    const previewData = await fetchLinkPreview(url);
    const updatedLinks = [...links, previewData];
    setLinks(updatedLinks);
    handleUpdateLinks(updatedLinks);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const url = event.dataTransfer.getData("text/plain");
    if (url) {
      addLink(url);
      setLink("");
    }
  };

  const handlePaste = (event) => {
    const pastedData = event.clipboardData.getData("text/plain");
    if (pastedData) {
      addLink(pastedData);
      setLink("");
    }
  };

  const handleRemoveLink = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
    handleUpdateLinks(updatedLinks);
  };

  return (
    <Box>
      {expandSource ? (
        <Box>
          <Box
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onPaste={handlePaste}
            sx={{
              border: expandSource ? "2px dashed grey" : "none",
              borderRadius: "10px",
              textAlign: "center",
              alignItems: "center",
              height: "100px",
              mt: 1,
              p: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BstSourceSearchTextField
              type="text"
              variant="standard"
              placeholder="Drag & Drop, Paste, or Enter a Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              sx={{
                padding: "5px",
                borderRadius: "5px",
                border: "none",
                width: "80%",
              }}
            />
          </Box>

          <BstSourceSearchTextField
            fullWidth
            variant="standard"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              mt: 1,
              borderRadius: "10px",
            }}
          />

          <Box sx={{ alignItems: "center" }}>
            <Box sx={{ mt: 1, maxHeight: "40vh", overflowY: "auto" }}>
              {filteredLinks.map((linkData, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    mt: 1,
                    gap: "10px",
                    position: "relative",
                    padding: "10px",
                    border: "1px solid grey",
                    borderRadius: "8px",
                  }}
                >
                  {/* Display image */}
                  {linkData.image && (
                    <img
                      src={linkData.image}
                      alt={linkData?.title}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "5px",
                        objectFit: "cover",
                      }}
                    />
                  )}

                  {/* Content Section */}
                  <Box sx={{ textAlign: "left", flex: 1 }}>
                    {/* Link Title */}
                    <Typography
                      component="a"
                      href={linkData.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#007BFF",
                        textDecoration: "none",
                        display: "block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px", // Adjust as needed
                      }}
                    >
                      {linkData?.title || linkData.url}
                    </Typography>

                    {/* Link Description */}
                    {/* <Typography
                      sx={{
                        fontSize: "12px",
                        lineHeight: "1.5",
                        display: "-webkit-box",
                        WebkitLineClamp: 5, // Approximate height constraint (5 lines)
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxHeight: "100px",
                      }}
                    >
                      {linkData.description}
                    </Typography> */}
                  </Box>

                  {/* Remove Button */}
                  <IconButton
                    onClick={() => handleRemoveLink(index)}
                    sx={{
                      position: "absolute",
                      top: "2px",
                      right: "5px",
                      color: "#FF4D4D",
                    }}
                    aria-label="Remove"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            mt: 1,
            p: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {filteredLinks.map((linkData, index) => (
            <Box
              key={index}
              sx={{
                mt: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                href={linkData.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                {linkData.image ? (
                  <img
                    src={linkData.image}
                    alt={""}
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Avatar sx={{ bgcolor: "#007BFF" }}>
                    {linkData?.title?.[0]?.toUpperCase() || "NA"}
                  </Avatar>
                )}
              </a>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default BstSources;
