import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
} from "@mui/material";
import axiosInstance from "./axiosInstance";

const BstDashboard = () => {
  const [stats, setStats] = useState({
    userCount: 0,
    dailyActiveUsers: [],
    documentCount: 0,
    subDocumentCount: 0,
    subDocumentTypeCounts: [],
    userCountGraph: [],
    sharedDocsCount: 0,
    mostActiveUsers: [],
  });

  const fetchStats = async () => {
    try {
      const [
        userCountRes,
        dailyActiveRes,
        docCountRes,
        subDocCountRes,
        subDocTypeRes,
        userGraphRes,
        sharedDocsRes,
        activeUsersRes,
      ] = await Promise.all([
        axiosInstance.get("/admin/user-count"),
        axiosInstance.get("/admin/daily-active-users"),
        axiosInstance.get("/admin/document-count"),
        axiosInstance.get("/admin/subdocument-count"),
        axiosInstance.get("/admin/subdocument-type-count"),
        axiosInstance.get("/admin/user-count-graph"),
        axiosInstance.get("/admin/documents-shared"),
        axiosInstance.get("/admin/most-active-users"),
      ]);

      setStats({
        userCount: userCountRes.data.count,
        dailyActiveUsers: dailyActiveRes.data.dailyActiveUsers,
        documentCount: docCountRes.data.count,
        subDocumentCount: subDocCountRes.data.count,
        subDocumentTypeCounts: subDocTypeRes.data.countsByType,
        userCountGraph: userGraphRes.data.userCountGraph,
        sharedDocsCount: sharedDocsRes.data.sharedDocsCount,
        mostActiveUsers: activeUsersRes.data.activeUsers,
      });
    } catch (error) {
      console.error("Failed to fetch dashboard stats", error);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  const formatGraphData = () => {
    const labels = stats.userCountGraph.map((data) => `Month ${data._id}`);
    const counts = stats.userCountGraph.map((data) => data.count);
    return {
      labels,
      datasets: [
        {
          label: "User Count",
          data: counts,
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <Container sx={{ padding: "40px" }}>
      <Typography
        variant="h1"
        sx={{ marginBottom: "30px", fontWeight: "bold", fontSize: "2.5rem" }}
      >
        Admin Dashboard
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <Typography
              variant="h2"
              sx={{ marginBottom: "10px", fontSize: "1.5rem" }}
            >
              Total Users
            </Typography>
            <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
              {stats.userCount}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <Typography
              variant="h2"
              sx={{ marginBottom: "10px", fontSize: "1.5rem" }}
            >
              Total Documents
            </Typography>
            <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
              {stats.documentCount}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <Typography
              variant="h2"
              sx={{ marginBottom: "10px", fontSize: "1.5rem" }}
            >
              Total Subdocuments
            </Typography>
            <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
              {stats.subDocumentCount}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box
            sx={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <Typography
              variant="h2"
              sx={{ marginBottom: "10px", fontSize: "1.5rem" }}
            >
              Shared Documents
            </Typography>
            <Typography variant="h3" sx={{ fontSize: "1.2rem" }}>
              {stats.sharedDocsCount}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Typography
        variant="h2"
        sx={{ marginTop: "40px", marginBottom: "20px", fontSize: "1.8rem" }}
      >
        Subdocument Type Counts
      </Typography>
      <List>
        {stats.subDocumentTypeCounts.map((type) => (
          <ListItem key={type._id} divider>
            <Typography variant="body1" sx={{ fontSize: "1rem" }}>
              {type._id ? type._id.toString().toUpperCase() : "Unknown Type"}:{" "}
              {type.count}
            </Typography>
          </ListItem>
        ))}
      </List>

      <Typography
        variant="h2"
        sx={{ marginTop: "40px", marginBottom: "20px", fontSize: "1.8rem" }}
      >
        Most Active Users
      </Typography>
      <List>
        {stats.mostActiveUsers.map((user) => (
          <ListItem key={user._id} divider>
            <Typography variant="body1" sx={{ fontSize: "1rem" }}>
              User ID: {user.email}, Documents: {user.documentCount}
            </Typography>
          </ListItem>
        ))}
      </List>

      <Typography
        variant="h2"
        sx={{ marginTop: "40px", marginBottom: "20px", fontSize: "1.8rem" }}
      >
        Daily Active Users (This Month)
      </Typography>
      <List>
        {stats.dailyActiveUsers.map((day) => (
          <ListItem key={day._id} divider>
            <Typography variant="body1" sx={{ fontSize: "1rem" }}>
              Day {day._id}: {day.count} users
            </Typography>
          </ListItem>
        ))}
      </List>

      <Typography
        variant="h2"
        sx={{ marginTop: "40px", marginBottom: "20px", fontSize: "1.8rem" }}
      >
        User Count Graph
      </Typography>
      <Box
        sx={{
          marginTop: "20px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
        }}
      >
        <Line data={formatGraphData()} />
      </Box>
    </Container>
  );
};

export default BstDashboard;
