import AceEditor from "react-ace";
import React, { useEffect, useRef, useState } from "react";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-dracula";
import "./highlight.css";

// mui
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Typography,
  Tooltip,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FileCopy } from "@mui/icons-material";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { BstTableCell, BstTextField } from "../CustomComponents/muiComponents";
// icons
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
//utils
import useBstApiRequest from "./api";
import { toast } from "react-toastify";
import parse from "@bany/curl-to-json";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ClearIcon } from "@mui/x-date-pickers/icons";

const methods = ["GET", "POST", "PUT", "PATCH", "DELETE"];

const generateCurlCommand = (headers, jsonBody, method, url) => {
  const headerString = headers
    .map((header) => `-H '${header.key}: ${header.value}'`)
    .join(" ");
  const bodyString = jsonBody ? `--data '${jsonBody}'` : "";
  return `curl --request ${method} '${url}' ${headerString} ${bodyString}`;
};

const BstApiForm = (props) => {
  const { subDocument, subDocumentId, saveDocument } = props;
  const data = useRef(subDocument.data);

  const [method, setMethod] = useState(data.current.method);
  const [url, setUrl] = useState(data.current.url);
  const [headers, setHeaders] = useState(data.current.headers);
  const [queryParams, setQueryParams] = useState(data.current.queryParams);
  const [jsonBody, setJsonBody] = useState(data.current.jsonBody);
  const [localResponse, setLocalResponse] = useState(data.current.response);
  const [status, setLocalStatus] = useState(data.current?.status);

  // eslint-disable-next-line no-unused-vars
  const [isLocalRequest, setIsLocalRequest] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [curlCommand, setCurlCommand] = useState("");
  const { sendRequest } = useBstApiRequest();
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [matches, setMatches] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSearch = () => {
    if (typeof localResponse !== "object") return;
    if (searchTerm === "") {
      setMatches([]);
      setCurrentIndex(0);
      return;
    }

    matches.forEach((match) => {
      match.classList.remove("highlight");
      match.classList.remove("light-highlight");
    });

    const editorElement = document.getElementById("json-editor");
    if (!editorElement) return;
    const lines = editorElement.querySelectorAll(".token");

    const matchLine = Array.from(lines).filter((line) =>
      line.textContent.includes(searchTerm)
    );

    matchLine.forEach((match) => {
      match.classList.add("light-highlight");
    });

    setMatches(matchLine);
    setCurrentIndex(0);
  };

  const handleNavigate = (direction) => {
    if (matches.length === 0) return;

    const newIndex =
      direction === "next"
        ? (currentIndex + 1) % matches.length
        : (currentIndex - 1 + matches.length) % matches.length;

    setCurrentIndex(newIndex);

    matches.forEach((match) => {
      match.classList.remove("highlight");
    });

    // Scroll to the match if found
    if (matches?.[newIndex]) {
      matches?.[newIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    matches?.[newIndex].classList.add("highlight");
  };

  const handleMethodChange = async (method) => {
    const newData = { ...data.current, method: method };
    saveDocument(newData, subDocumentId);
    setMethod(method);
    data.current = newData;
  };

  function parseQueryParamsFromUrl(url) {
    try {
      const params = new URL(url).searchParams;

      // Convert params to an array, then map to desired format
      const formattedParams = Array.from(params.entries()).map(
        ([key, value]) => ({
          key,
          value,
        })
      );

      return formattedParams;
    } catch (e) {
      console.error(e);
      return queryParams;
    }
  }

  const handleUrlChange = async (url) => {
    const newParams = parseQueryParamsFromUrl(url);
    const newData = { ...data.current, url: url, queryParams: newParams };
    saveDocument(newData, subDocumentId);
    setUrl(url);
    setQueryParams(newParams);
    data.current = newData;
  };

  const handleHeadersChange = async (headers) => {
    const newData = { ...data.current, headers: headers };
    saveDocument(newData, subDocumentId);
    setHeaders(headers);
    data.current = newData;
  };

  function buildUrlFromQueryParams(baseUrl, queryParams) {
    try {
      const urlWithoutQuery = baseUrl.split("?")[0];
      const url = new URL(urlWithoutQuery);

      // Add each key-value pair to the URL's search parameters
      queryParams.forEach(({ key, value }) => {
        if (key) url.searchParams.append(key, decodeURIComponent(value));
      });

      return url.toString();
    } catch (e) {
      console.error(e);
      return baseUrl;
    }
  }

  const handleQueryParamsChange = async (queryParams) => {
    let newUrl = url;
    if (url) {
      newUrl = buildUrlFromQueryParams(url, queryParams);
      setUrl(newUrl);
    }
    const newData = { ...data.current, queryParams: queryParams };
    saveDocument(newData, subDocumentId);
    setQueryParams(queryParams);
    data.current = newData;
  };

  const handleJsonBodyChange = async (jsonBody) => {
    const newData = { ...data.current, jsonBody: jsonBody };
    saveDocument(newData, subDocumentId);
    setJsonBody(jsonBody);
    data.current = newData;
  };

  const handleResponseChange = async (response, status) => {
    const newData = { ...data.current, response: response, status: status };
    saveDocument(newData, subDocumentId);
    setLocalResponse(response);
    setLocalStatus(status);
    data.current = newData;
  };

  const onSubmit = async (body) => {
    const { data, status } = await sendRequest(body);
    handleResponseChange(data, status);
  };

  const onClear = () => {
    handleResponseChange(null, null);
  };

  useEffect(() => {
    const isLocal =
      url?.startsWith("http://localhost") ||
      url?.startsWith("http://127.0.0.1");
    setIsLocalRequest(isLocal);
    setCurlCommand(generateCurlCommand(headers, jsonBody, method, url));
  }, [method, url, headers, jsonBody]);

  const parseCurlCommand = async (curlString) => {
    curlString = curlString?.replace(/\\/g, "");
    let parsedData = "";
    try {
      parsedData = parse(curlString);
    } catch {
      handleUrlChange(curlString);
      return;
    }
    handleMethodChange(parsedData.method || "GET");
    const formattedParams = Object.entries(parsedData.params || {}).map(
      ([key, value]) => ({
        key,
        value,
      })
    );
    handleQueryParamsChange(formattedParams);

    const url = buildUrlFromQueryParams(
      parsedData.url || parsedData.location || "",
      formattedParams
    );

    handleUrlChange(url);

    const formattedHeaders = Object.entries(parsedData.header || {}).map(
      ([key, value]) => ({
        key,
        value,
      })
    );
    handleHeadersChange(formattedHeaders);

    handleJsonBodyChange(
      parsedData.body || parsedData.data
        ? JSON.stringify(parsedData.body || parsedData.data, null, 2)
        : ""
    );
  };

  const handleCurlOrUrlChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue?.startsWith("curl")) {
      parseCurlCommand(inputValue);
    } else {
      handleUrlChange(inputValue);
    }
  };

  // Handle dynamic fields for headers and query params
  const handleAddField = (setter, fields) => {
    setter([...fields, { key: "", value: "" }]);
  };

  const handleRemoveField = (setter, fields, index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setter(newFields);
  };

  const handleFieldChange = (setter, fields, index, field, value) => {
    const newFields = fields.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setter(newFields);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formattedHeaders = headers.reduce((acc, header) => {
        if (header.key) acc[header.key] = header.value;
        return acc;
      }, {});

      // Exclude specific headers if the request is local
      if (isLocalRequest) {
        delete formattedHeaders["Sec-Fetch-Site"];
        delete formattedHeaders["Priority"];
      }

      const formattedQueryParams = new URLSearchParams();
      queryParams.forEach(({ key, value }) => {
        if (key) formattedQueryParams.append(key, value);
      });

      const body = jsonBody ? JSON.parse(jsonBody) : null;

      await onSubmit({
        method,
        url,
        headers: formattedHeaders,
        queryParams: formattedQueryParams,
        body,
      });
      setCurlCommand(generateCurlCommand(headers, jsonBody, method, url));
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const copyCurlToClipboard = () => {
    navigator.clipboard
      .writeText(curlCommand)
      .then(() =>
        toast.success("cURL copied!", {
          position: "bottom-right",
          autoClose: 500,
          progress: false,
          hideProgressBar: true,
        })
      )
      .catch((err) => {
        console.error("Failed to copy cURL command: ", err);
      });
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Method selection next to URL input */}
          <Box>
            <FormControl>
              <Select
                sx={{ height: "25px" }}
                value={method}
                onChange={(e) => handleMethodChange(e.target.value)}
              >
                {methods.map((method) => (
                  <MenuItem key={method} value={method}>
                    {method}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* URL input */}
          <Box sx={{ width: "80%" }}>
            <BstTextField
              placeholder="API URL or cURL"
              variant="standard"
              value={url}
              onChange={handleCurlOrUrlChange}
              sx={{ width: "100%", ml: 1, mr: 1 }}
            />
          </Box>

          {/* Submit Button */}
          <Box>
            <LoadingButton
              loading={loading}
              sx={{ height: "25px" }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Send
            </LoadingButton>
          </Box>
        </Box>

        {isLocalRequest && (
          <Alert severity="error" sx={{ width: "100%", mt: 1 }}>
            Local request might throw CORS error if all origins are not allowed
            in your test environment
          </Alert>
        )}

        {/* Tabs for Query Params, Headers, and Body */}
        <Box sx={{ width: "100%", marginTop: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Tabs
              sx={{ width: "50%" }}
              value={tabIndex}
              onChange={(e, newIndex) => setTabIndex(newIndex)}
            >
              <Tab label="Query Params" />
              <Tab label="Headers" />
              <Tab label="Body" />
              <Tab label="cURL" />
            </Tabs>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                justifyItems: "center",
                width: "50%",
              }}
            >
              <Typography sx={{ ml: 1 }}>Response</Typography>

              <Box>
                <Typography
                  sx={{
                    ml: 1,
                    color:
                      (status === 200 && "green") ||
                      (status >= 400 && status < 500 && "orange") ||
                      (status >= 500 && "red") ||
                      "grey",
                  }}
                >
                  {status === 200
                    ? `✅ Success (${status})`
                    : status >= 400 && status < 500
                    ? `⚠️ Client Error (${status})`
                    : status >= 500
                    ? `❌ Server Error (${status})`
                    : `ℹ️ ${status || "No Status"}`}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <BstTextField
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  size="small"
                />
                <IconButton
                  color="secondary"
                  onClick={handleSearch}
                  aria-label="search"
                  size="small"
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={matches.length === 0}
                  onClick={() => handleNavigate("prev")}
                  aria-label="previous"
                  size="small"
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={matches.length === 0}
                  onClick={() => handleNavigate("next")}
                  aria-label="next"
                  size="small"
                >
                  <ArrowForwardIosIcon />
                </IconButton>
                {matches.length > 0 && (
                  <Typography>
                    {currentIndex + 1} of {matches.length}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          {/* Tab Panels */}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ width: "50%", maxHeight: "500px", overflowY: "auto" }}>
              {tabIndex === 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {queryParams.map((param, index) => (
                        <TableRow key={index}>
                          <BstTableCell sx={{ ml: 1 }}>
                            <BstTextField
                              sx={{ ml: 1, width: "95%" }}
                              value={param.key}
                              onChange={(e) =>
                                handleFieldChange(
                                  handleQueryParamsChange,
                                  queryParams,
                                  index,
                                  "key",
                                  e.target.value
                                )
                              }
                            />
                          </BstTableCell>
                          <BstTableCell sx={{ ml: 1 }}>
                            <BstTextField
                              sx={{ ml: 1, width: "95%" }}
                              value={param.value}
                              onChange={(e) =>
                                handleFieldChange(
                                  handleQueryParamsChange,
                                  queryParams,
                                  index,
                                  "value",
                                  e.target.value
                                )
                              }
                            />
                          </BstTableCell>
                          <BstTableCell>
                            <IconButton
                              onClick={() =>
                                handleRemoveField(
                                  handleQueryParamsChange,
                                  queryParams,
                                  index
                                )
                              }
                            >
                              <RemoveCircle color="error" />
                            </IconButton>
                          </BstTableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <BstTableCell colSpan={3}>
                          <Button
                            onClick={() =>
                              handleAddField(
                                handleQueryParamsChange,
                                queryParams
                              )
                            }
                            startIcon={<AddCircle />}
                            fullWidth
                          >
                            Add Query Param
                          </Button>
                        </BstTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {tabIndex === 1 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {headers.map((header, index) => (
                        <TableRow key={index}>
                          <BstTableCell sx={{ ml: 1 }}>
                            <BstTextField
                              sx={{ ml: 1, width: "95%" }}
                              value={header.key}
                              onChange={(e) =>
                                handleFieldChange(
                                  handleHeadersChange,
                                  headers,
                                  index,
                                  "key",
                                  e.target.value
                                )
                              }
                              fullWidth
                            />
                          </BstTableCell>
                          <BstTableCell sx={{ ml: 1 }}>
                            <BstTextField
                              sx={{ ml: 1, width: "95%" }}
                              value={header.value}
                              onChange={(e) =>
                                handleFieldChange(
                                  handleHeadersChange,
                                  headers,
                                  index,
                                  "value",
                                  e.target.value
                                )
                              }
                              fullWidth
                            />
                          </BstTableCell>
                          <BstTableCell sx={{ ml: 1 }}>
                            <IconButton
                              onClick={() =>
                                handleRemoveField(
                                  handleHeadersChange,
                                  headers,
                                  index
                                )
                              }
                            >
                              <RemoveCircle color="error" />
                            </IconButton>
                          </BstTableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <BstTableCell colSpan={3}>
                          <Button
                            onClick={() =>
                              handleAddField(handleHeadersChange, headers)
                            }
                            startIcon={<AddCircle />}
                            fullWidth
                          >
                            Add Header
                          </Button>
                        </BstTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {tabIndex === 2 && (
                <Box>
                  <AceEditor
                    mode="json"
                    theme="dracula"
                    value={jsonBody}
                    onChange={handleJsonBodyChange}
                    name="jsonEditor"
                    editorProps={{ $blockScrolling: true }}
                    width="100%"
                    height="500px"
                    style={{ borderRadius: "10px" }}
                    setOptions={{
                      useWorker: false, // Disable web workers
                    }}
                  />
                </Box>
              )}

              {tabIndex === 3 && (
                <Box
                  sx={{
                    padding: 2,
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                  }}
                >
                  <Box display="flex" alignItems="center" mb={1}>
                    <Typography variant="h6" gutterBottom>
                      Generated cURL Command:
                    </Typography>
                    <Tooltip title="Copy to Clipboard">
                      <IconButton
                        onClick={copyCurlToClipboard}
                        sx={{ marginLeft: 1 }}
                      >
                        <FileCopy />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box
                    component={Paper}
                    sx={{
                      whiteSpace: "pre-wrap", // Preserve whitespace and line breaks
                      wordBreak: "break-word", // Break long lines
                      fontFamily: "monospace", // Use monospace font
                      padding: 1,
                      borderRadius: "10px",
                      overflowX: "auto", // Enable horizontal scrolling if necessary
                    }}
                  >
                    {curlCommand}
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                width: "50%",
                ml: 1,
                border: "1px solid grey",
                borderRadius: "10px",
              }}
            >
              {localResponse ? (
                <Box sx={{ position: "relative", width: "100%" }}>
                  {/* Cancel Icon */}
                  <IconButton
                    aria-label="close"
                    onClick={onClear}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      zIndex: 1,
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                      },
                    }}
                    size="small"
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>

                  {/* Syntax Highlighter */}
                  <Box
                    sx={{
                      maxHeight: "500px",
                      overflowY: "auto",
                      borderRadius: "10px",
                      position: "relative",
                    }}
                  >
                    {typeof localResponse === "object" ? (
                      <SyntaxHighlighter
                        id="json-editor"
                        language="json"
                        style={darcula}
                        wrapLongLines
                        customStyle={{
                          margin: 0,
                          fontSize: "12px",
                          height: "500px",
                        }}
                      >
                        {JSON.stringify(localResponse, null, 2)}
                      </SyntaxHighlighter>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{ __html: localResponse }}
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography>No Response Yet</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default BstApiForm;
