import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useContext,
} from "react";
// components
import Quill from "quill";
import { styled } from "@mui/material";
// custom components
import BstLayout from "./BstLayout";
import { subDocHeight } from "./constants";
import { ThemeContext } from "./BstThemeProvider";
import "./quill.css";

const fontSizeArr = [
  "8px",
  "9px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];

var Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

const TOOLBAR_OPTIONS = [
  [{ font: [] }],
  [{ size: fontSizeArr }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  ["bold", "italic", "underline", "strike"],
  [{ color: [] }, { background: [] }],
  [{ script: "sub" }, { script: "super" }],
  [{ align: [] }],
  ["blockquote", "code-block"],
];

const SAVE_DEBOUNCE_INTERVAL = 1000;

// Style for Quill editor with fixed height and scroll
const QuillContainer = styled("div")(({ subDocHeight, theme }) => ({
  overflow: "auto", // Enable scrolling
  "& .ql-editor": {
    minHeight: subDocHeight, // Ensure editor content area uses full height
    height: subDocHeight,
  },
  "& .ql-container": {
    border: "none", // Remove border
  },
  "& .ql-toolbar": {
    border: "none", // Remove border
    borderBottom: "1px solid grey",
  },
  "& .ql-formats": {
    color: theme === "light" ? "black" : "white",
  },
  "& .ql-stroke": {
    stroke: theme === "light" ? "black" : "white",
  },
  "& .ql-strike": {
    stroke: theme === "light" ? "black" : "white",
  },
  "& .ql-script": {
    stroke: theme === "light" ? "black" : "white",
  },
  "& .ql-picker": {
    color: theme === "light" ? "black" : "white",
  },
  "& .ql-picker-options": {
    backgroundColor: theme === "light" ? "white" : "grey",
  },
}));

const BstEditor = forwardRef((props, ref) => {
  const {
    subDocument,
    writePermission,
    expand,
    handlSelectSubDoc,
    fullScreen,
    selectedSubDocId,
    saveDocument,
  } = props;
  const data = useRef(subDocument.data);
  const subDocumentId = subDocument._id;
  const isFullscreen = fullScreen[subDocumentId];
  const [quill, setQuill] = useState();
  const [saveTimeout, setSaveTimeout] = useState(null);
  const isExpanded = expand[subDocumentId];
  const [isFocused, setIsFocused] = useState(false);

  const wrapperRef = useCallback(
    (wrapper) => {
      if (wrapper == null) return;
      wrapper.innerHTML = "";
      const editor = document.createElement("div");
      wrapper.append(editor);
      const q = new Quill(editor, {
        theme: "snow",
        modules: { toolbar: TOOLBAR_OPTIONS },
        keyboard: {
          bindings: {
            bold: {
              key: "B",
              shortKey: true,
              handler: function () {
                q.format("bold", !q.getFormat().bold);
              },
            },
            italic: {
              key: "I",
              shortKey: true,
              handler: function () {
                q.format("italic", !q.getFormat().italic);
              },
            },
            underline: {
              key: "U",
              shortKey: true,
              handler: function () {
                q.format("underline", !q.getFormat().underline);
              },
            },
            redo: {
              key: "Y",
              shortKey: true, // Ctrl+Y or Cmd+Y for redo
              handler: function () {
                console.log("hello");
                q.history.redo(); // Perform redo
              },
            },
          },
        },
      });
      q.setText("Loading...");
      q.setContents(data.current);
      q.history.clear();

      if (writePermission) {
        q.enable();
      } else {
        q.disable();
      }
      setQuill(q);
    },
    [data, writePermission]
  );

  useEffect(() => {
    const sizePicker = document.querySelector(".ql-size");
    if (sizePicker) {
      sizePicker.addEventListener("click", () => {
        const activeItem = sizePicker.querySelector(".ql-active");
        const label = sizePicker.querySelector(".ql-picker-label");
        if (activeItem && label) {
          const value = activeItem.getAttribute("data-value");
          label.innerHTML = `-${value}`;
        }
      });
    }

    return () => {
      sizePicker?.removeEventListener("click", () => {}); // Cleanup listener
    };
  });

  useEffect(() => {
    if (!quill) return;

    const handleRedo = (event) => {
      const isMac = navigator.userAgent.includes("Mac");
      if (
        ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) &&
        event.key === "y"
      ) {
        event.preventDefault();
        quill.history.redo();
      }
    };

    const handleFocus = (event) => {
      const isMac = navigator.userAgent.includes("Mac");
      if (
        ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) &&
        event.key === "Enter" &&
        subDocumentId === selectedSubDocId &&
        !isFocused
      ) {
        if (quill) {
          quill.focus();
        }
      }
    };

    document.addEventListener("keydown", handleRedo);
    document.addEventListener("keydown", handleFocus);

    // Cleanup the  listener on unmount
    return () => {
      document.addEventListener("keydown", handleFocus);
      document.removeEventListener("keydown", handleRedo);
    };
  }, [quill, subDocumentId, selectedSubDocId, isFocused]);

  // handle focus in quill
  useEffect(() => {
    if (!quill) return;

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    quill.on("focus", handleFocus);
    quill.on("blur", handleBlur);

    return () => {
      quill.off("focus", handleFocus);
      quill.off("blur", handleBlur);
    };
  }, [quill]);

  // Update document
  useEffect(() => {
    const handleSaveDocument = async () => {
      try {
        const contents = quill.getContents();
        data.current = contents;
        saveDocument(data.current, subDocumentId);
      } catch (error) {
        console.error("Failed to save the document:", error);
      }
    };

    if (quill) {
      const handleChange = () => {
        if (saveTimeout) {
          clearTimeout(saveTimeout);
        }
        const timeout = setTimeout(handleSaveDocument, SAVE_DEBOUNCE_INTERVAL);
        setSaveTimeout(timeout);
      };

      quill.on("text-change", handleChange);
      return () => {
        quill.off("text-change", handleChange);
        if (saveTimeout) {
          clearTimeout(saveTimeout);
        }
      };
    }
  }, [quill, subDocumentId, saveTimeout, saveDocument]);

  const { mode } = useContext(ThemeContext);

  return (
    <BstLayout
      isFullscreen={isFullscreen}
      isExpanded={isExpanded}
      subDocumentId={subDocumentId}
      handleSelectSubDoc={handlSelectSubDoc}
      {...props}
    >
      <QuillContainer
        subDocHeight={isFullscreen ? "85vh" : subDocHeight}
        theme={mode}
        ref={wrapperRef}
      ></QuillContainer>
    </BstLayout>
  );
});

export default BstEditor;
