import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import theme from "./theme";
// components
import Login from "./components/login";
import Register from "./components/register";
import PasswordReset from "./components/passwordReset.js";
import Layout from "./components/Layout/layout";
import Bst from "./components/Bst.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";
import BstSouces from "./components/BstSources.js";
import { BstThemeProvider } from "./components/BstThemeProvider.js";
import BstDashboard from "./components/BstDashboard.js";

function App() {
  const [open, setOpen] = useState(true);
  console.log(process.env.REACT_APP_CLIENT_ID);

  return (
    <BstThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/reset" element={<PasswordReset />} />
            <Route path="/register" element={<Register />} />
            <Route path="/sources" element={<BstSouces />} />
            <Route
              path="/documents"
              element={<Layout open={open} setOpen={setOpen} />}
            />
            <Route path="/documents/:id" element={<Bst />} />
            <Route path="/" element={<Navigate to={"/login"} />} />
            <Route path="/admin" element={<BstDashboard />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </BstThemeProvider>
  );
}

export default App;
