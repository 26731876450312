import { Box } from "@mui/material";
import React from "react";

// bst api
import BstApiForm from "./form";
import BstLayout from "../BstLayout";

export default function BstApiMain(props) {
  const { subDocument, expand, handlSelectSubDoc, fullScreen } = props;

  const subDocumentId = subDocument._id;
  const isFullscreen = fullScreen[subDocumentId];
  const isExpanded = expand[subDocumentId];

  return (
    <BstLayout
      isFullscreen={isFullscreen}
      isExpanded={isExpanded}
      subDocumentId={subDocumentId}
      handleSelectSubDoc={handlSelectSubDoc}
      {...props}
    >
      <Box sx={{ p: 1 }}>
        <BstApiForm
          subDocument={subDocument}
          subDocumentId={subDocumentId}
          {...props}
        />
      </Box>
    </BstLayout>
  );
}
