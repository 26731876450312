import React from "react";
import axiosInstance from "./axiosInstance";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import { GoogleLogin } from "@react-oauth/google";

export default function GoogleSignIn() {
  const navigate = useNavigate();

  const handleLogin = async (response) => {
    const token = response.credential;

    try {
      const response = await axiosInstance.post("/auth/gauth/", {
        token: token,
      });
      localStorage.setItem("accessToken", response.data.accessToken);
      navigate("/documents");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ width: "100%", mb: 1 }}>
      <GoogleLogin
        onSuccess={handleLogin}
        width={350}
        onFailure={(error) => console.log("Login Failed", error)}
        cookiePolicy={"single_host_origin"}
      />
    </Box>
  );
}
