import axios from "axios";
import { useState } from "react";
import axiosInstance from "../axiosInstance";

export default function useBstApiRequest() {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = async ({ method, url, headers, queryParams, body }) => {
    setLoading(true);
    try {
      const isLocalRequest =
        url?.startsWith("http://localhost") ||
        url?.startsWith("http://127.0.0.1");

      const config = {
        method,
        url: `${url}`,
        headers,
        data: body,
      };

      const res = isLocalRequest
        ? await axios(config) // Direct local request
        : await axiosInstance.post("/proxy-server", { config: config }); // Proxy request

      setResponse(res?.data?.data);
      return { data: res?.data, status: res?.data.status };
    } catch (err) {
      setError(err?.response?.data?.message);

      return {
        data: err?.response?.data?.message,
        status: err?.response?.data?.status,
      };
    } finally {
      setLoading(false);
    }
  };

  return { response, error, loading, sendRequest };
}
