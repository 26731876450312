import React, { useState, useRef, useEffect } from "react";
// mui
import { RemoveCircle } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, IconButton } from "@mui/material";

//custom components
import {
  BstTableColumnTextField,
  BstTableTextField,
} from "./CustomComponents/muiComponents";
import { subDocHeight } from "./constants";
import BstLayout from "./BstLayout";

export const initialRows = [["", "", "", "", "", ""]];
export const initialColumns = [
  "Column 1",
  "Column 2",
  "Column 3",
  "Column 4",
  "Column 5",
  "Column 6",
];

export default function BstTable(props) {
  const {
    subDocument,
    saveDocument,
    writePermission,
    expand,
    handlSelectSubDoc,
    selectedSubDocId,
    fullScreen,
  } = props;

  const subDocumentId = subDocument._id;
  const isExpanded = expand[subDocumentId];
  const data = subDocument.data;
  const isFullscreen = fullScreen[subDocumentId];

  const colWidth = 200;
  const [columnHeader, setColumnHeader] = useState(data.columns);
  const [table, setTable] = useState(data.rows);

  const refs = useRef({}); // Store refs for all cells
  const getCellKey = (rowIndex, colIndex) => `${rowIndex}-${colIndex}`;

  // Row Operations
  const addRow = () => {
    const colLen = columnHeader.length;
    const newRow = new Array(colLen).fill("");
    const newTable = [...table, newRow];
    setTable(newTable);
    saveDocument({ rows: newTable, columns: columnHeader }, subDocumentId);
  };

  const removeRow = (rowIndex) => {
    const newTable = table.filter((_, index) => index !== rowIndex);
    setTable(newTable);
    saveDocument({ rows: newTable, columns: columnHeader }, subDocumentId);
  };

  // Column operation
  const addColumn = () => {
    const colLen = columnHeader.length;
    const newColumnName = `Column ${colLen + 1}`;
    const newColumns = [...columnHeader, newColumnName];
    setColumnHeader(newColumns);

    // add rows to this column
    const updatedTable = table.map((row) => [...row, ""]);
    setTable(updatedTable);
    saveDocument({ rows: updatedTable, columns: newColumns }, subDocumentId);
  };

  const removeColumn = (columnIndex) => {
    // Remove column header
    const newColumnHeader = columnHeader.filter(
      (_, index) => index !== columnIndex
    );
    setColumnHeader(newColumnHeader);

    // Remove the corresponding column from each row
    const updatedTable = table.map((row) => {
      const newRow = row.filter((_, index) => index !== columnIndex);
      return newRow;
    });
    setTable(updatedTable);
    saveDocument(
      { rows: updatedTable, columns: newColumnHeader },
      subDocumentId
    );
  };

  // cell operation
  const updateColumn = (event, columnIndex) => {
    const text = event.target.value;
    const newColumn = [...columnHeader]; // Create a copy of columnHeader
    newColumn[columnIndex] = text;
    setColumnHeader(newColumn);
    saveDocument({ rows: table, columns: newColumn }, subDocumentId);
  };

  const updateCell = (event, rowIndex, columnIndex) => {
    const text = event.target.value;
    const newTable = table.map((row, i) => {
      if (i === rowIndex) {
        const updatedRow = [...row];
        updatedRow[columnIndex] = text; // Modify the specific cell
        return updatedRow;
      }
      return row;
    });
    setTable(newTable);
    saveDocument({ rows: newTable, columns: columnHeader }, subDocumentId);
  };

  useEffect(() => {
    const handleSelectFirstCell = (event) => {
      const isMac = navigator.userAgent.includes("Mac");
      if (
        ((isMac && event.metaKey) || (!isMac && event.ctrlKey)) &&
        event.key === "Enter" &&
        subDocumentId === selectedSubDocId
      ) {
        const cellRef = refs.current[getCellKey(0, 0)];
        if (cellRef) {
          cellRef?.focus();
          const length = cellRef.value.length;
          cellRef.setSelectionRange(length, length);
          event.preventDefault();
        }
      }
    };

    document.addEventListener("keydown", handleSelectFirstCell);

    return () => {
      document.addEventListener("keydown", handleSelectFirstCell);
    };
  }, [subDocumentId, selectedSubDocId]);

  const handleKeyDown = (event, rowIndex, columnIndex) => {
    const cellKey = getCellKey(rowIndex, columnIndex);
    const currentRef = refs.current[cellKey];
    if (!currentRef) return;

    const cursorPosition = currentRef.selectionStart;

    switch (event.key) {
      case "ArrowLeft":
        if (
          (!event.metaKey || !event.ctrlKey) &&
          cursorPosition === 0 &&
          columnIndex > 0
        ) {
          refs.current[getCellKey(rowIndex, columnIndex - 1)]?.focus();
          event.preventDefault();
        }
        break;
      case "ArrowRight":
        if (
          (!event.metaKey || !event.ctrlKey) &&
          cursorPosition === currentRef.value.length &&
          columnIndex < columnHeader.length - 1
        ) {
          refs.current[getCellKey(rowIndex, columnIndex + 1)]?.focus();
          event.preventDefault();
        }
        break;
      case "ArrowUp":
        if ((!event.metaKey || !event.ctrlKey) && rowIndex > 0) {
          refs.current[getCellKey(rowIndex - 1, columnIndex)]?.focus();
          event.preventDefault();
        }
        break;
      case "ArrowDown":
        if ((!event.metaKey || !event.ctrlKey) && rowIndex < table.length - 1) {
          refs.current[getCellKey(rowIndex + 1, columnIndex)]?.focus();
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  const boxRef = useRef(null);
  const [boxMaxWidth, setBoxMaxWidth] = useState("100%");

  useEffect(() => {
    if (boxRef.current) {
      const parentWidth = boxRef.current.parentElement.offsetWidth;
      const columnCount = Math.floor(parentWidth / colWidth);
      setBoxMaxWidth(`${columnCount * colWidth - colWidth}px`);
    }
  }, [colWidth, isFullscreen]); // Recalculate when colWidth or fullscreen changes

  return (
    <BstLayout
      isFullscreen={isFullscreen}
      isExpanded={isExpanded}
      subDocumentId={subDocumentId}
      handleSelectSubDoc={handlSelectSubDoc}
      {...props}
    >
      <Box
        ref={boxRef}
        sx={{
          height: isFullscreen ? "85vh" : subDocHeight,
          overflow: "auto",
          borderTop: "1px solid grey",
        }}
      >
        {/* column header */}
        <Box sx={{ maxWidth: boxMaxWidth }}>
          <Box sx={{ display: "flex" }}>
            {columnHeader.map((_, columnIndex) => (
              <Box
                key={columnIndex}
                sx={{
                  display: "flex",
                  width: colWidth + "px",
                  textAlign: "center",
                  alignItems: "center",
                  overflowWrap: "normal",
                }}
              >
                <BstTableColumnTextField
                  value={columnHeader[columnIndex]}
                  onChange={(event) => updateColumn(event, columnIndex)}
                />
                <IconButton
                  size="small"
                  onClick={() => removeColumn(columnIndex)}
                >
                  <RemoveCircle />
                </IconButton>
              </Box>
            ))}
            <IconButton variant="contained" onClick={addColumn}>
              <AddIcon />
            </IconButton>
          </Box>
          {/* table */}
          <Box>
            {table.map((row, rowIndex) => (
              <Box key={rowIndex} sx={{ display: "flex" }}>
                {row.map((_, columnIndex) => {
                  const cellKey = getCellKey(rowIndex, columnIndex);
                  return (
                    <Box key={columnIndex} sx={{ width: colWidth + "px" }}>
                      <BstTableTextField
                        autoComplete="off"
                        inputRef={(ref) => {
                          refs.current[cellKey] = ref;
                        }}
                        value={table[rowIndex][columnIndex]}
                        onChange={(event) =>
                          updateCell(event, rowIndex, columnIndex)
                        }
                        onKeyDown={(event) =>
                          handleKeyDown(event, rowIndex, columnIndex)
                        }
                        fullWidth
                      />
                    </Box>
                  );
                })}
                <IconButton onClick={() => removeRow(rowIndex)}>
                  <RemoveCircle />
                </IconButton>
              </Box>
            ))}
            <IconButton
              sx={{ width: `${columnHeader.length * colWidth}px` }}
              variant="contained"
              onClick={addRow}
              disableFocusRipple={true}
            >
              <AddIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      {!writePermission && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "transparent",
            zIndex: 10,
          }}
        ></Box>
      )}
    </BstLayout>
  );
}
