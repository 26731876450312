import React, { useEffect, useState } from "react";

// components
import { Box } from "@mui/material";
// custom components
import BstHeader from "./BstHeader";
import { FullScreenContainer } from "./CustomComponents/muiComponents";

export default function BstLayout(props) {
  const {
    toLayout,
    subDocument,
    docType,
    handleHeadingChange,
    handleOpenConfirm,
    writePermission,
    handleExpand,
    expand,
    handlSelectSubDoc,
    provided,
    children,
    handleFocus,
    handleUnFocus,
    fullScreen,
    handleFullScreen,
    selectedSubDocId,
  } = props;
  const data = subDocument.data;
  const subDocumentId = subDocument._id;
  const isFullscreen = fullScreen[subDocumentId];
  const [heading, setHeading] = useState(subDocument.heading);
  const isExpanded = expand[subDocumentId];

  // useEffect to handle keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (e) => {
      const isMac = navigator.userAgent.includes("Mac");
      if (!toLayout) {
        if (
          ((isMac && e.metaKey) || (!isMac && e.ctrlKey)) &&
          e.key === "ArrowRight"
        ) {
          e.preventDefault();
          if (!expand[selectedSubDocId]) {
            handleExpand(selectedSubDocId, true);
          } else {
            handleFullScreen(selectedSubDocId, true); // Enter fullscreen on Ctrl + UpArrow
          }
        } else if (
          (((isMac && e.metaKey) || (!isMac && e.ctrlKey)) &&
            e.key === "ArrowLeft") ||
          e.key === "Escape"
        ) {
          e.preventDefault();
          if (fullScreen[selectedSubDocId]) {
            handleFullScreen(selectedSubDocId, false);
          } else if (expand[selectedSubDocId]) {
            handleExpand(selectedSubDocId, false);
          }
        } else if (e.key === "Enter") {
          if (!fullScreen[selectedSubDocId] && !expand[selectedSubDocId]) {
            handleExpand(selectedSubDocId, true);
            // handleFocus();
          }
        } else if (e.key === "Escape") {
          if (!fullScreen[selectedSubDocId] && expand[selectedSubDocId]) {
            handleExpand(selectedSubDocId, false);
            // handleUnFocus();
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    toLayout,
    handleFullScreen,
    selectedSubDocId,
    handleExpand,
    expand,
    fullScreen,
    handleFocus,
    handleUnFocus,
  ]);

  return (
    <FullScreenContainer
      onClick={() =>
        handlSelectSubDoc({ heading, data, docType, isExpanded }, subDocument)
      }
      isFullscreen={isFullscreen}
    >
      <Box>
        <BstHeader
          heading={heading}
          writePermission={writePermission}
          setHeading={setHeading}
          handleHeadingChange={handleHeadingChange}
          subDocumentId={subDocumentId}
          isExpanded={isExpanded}
          handleOpenConfirm={handleOpenConfirm}
          handleExpand={handleExpand}
          fullScreen={fullScreen}
          handleFullScreen={handleFullScreen}
          // isFullscreen={isFullscreen}
          // setIsFullscreen={setIsFullscreen}
          docType={docType}
          provided={provided}
        />
        {(isExpanded || isFullscreen) && children}
      </Box>
    </FullScreenContainer>
  );
}
