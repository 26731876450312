import {
  blue,
  brown,
  green,
  grey,
  orange,
  pink,
  purple,
  red,
  yellow,
} from "@mui/material/colors";
import { createTheme } from "@mui/material";

const getTheme = (mode) =>
  createTheme({
    palette: {
      mode,
      background: {
        default: mode === "dark" ? "#121212" : grey[100],
        paper: mode === "dark" ? "#1b1b1b" : grey[50],
      },
      primary: {
        main: blue[500],
      },
      secondary: {
        main: red[500],
      },
      lightBlue: {
        main: blue[300],
      },
      brown: {
        main: brown[300],
      },
      yellow: {
        main: yellow[300],
      },
      green: {
        main: green[300],
      },
      orange: {
        main: orange[300],
      },
      black: {
        main: grey[300],
      },
      purple: {
        main: purple[300],
      },
      pink: {
        main: pink[300],
      },
    },
  });

export default getTheme;
