import { toast } from "react-toastify";

export const createEmptySheet = (rows, cols) => {
  return Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => ({ value: "" }))
  );
};

export const toastError = (message, error) => {
  console.log(message, error);
  toast.error(message, {
    position: "bottom-right",
    autoClose: 500,
    progress: false,
    hideProgressBar: true,
  });
};
