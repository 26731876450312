import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import BstDeleteConfirm from "../BstDeleteConfirm";
import { BstDocumenSearchTextField } from "../CustomComponents/muiComponents";

const DocumentList = ({
  open,
  documents,
  selectedDocId,
  selectedDocIndex,
  handleDelete,
  handleDocumentClick,
  toLayout,
  shared,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [docToDelete, setDocToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpenConfirm = (id) => {
    setDocToDelete(id);
    setConfirmOpen(true);
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
    setDocToDelete(null);
  };

  const handleConfirmDelete = () => {
    handleDelete(docToDelete);
    handleCloseConfirm();
  };

  const filteredDocuments = documents.filter((doc) =>
    doc.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={{ height: "100vh", overflow: "auto", textAlign: "center" }}>
      <BstDocumenSearchTextField
        placeholder="Search documents..."
        variant="standard"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mt: 1, p: 1, borderRadius: "10px", width: "94%" }}
      />

      <List sx={{ p: 1 }}>
        {filteredDocuments.map((item, index) => (
          <ListItem
            key={item._id}
            disablePadding
            sx={{
              display: "block",
              borderRadius: "10px",
              marginTop: 1,
              backgroundColor:
                selectedDocId === item._id
                  ? "rgba(33, 150, 243, 0.25)"
                  : "transparent",
              border:
                toLayout && index === selectedDocIndex && !shared
                  ? "1px solid #2196f3"
                  : "none",
            }}
          >
            <ListItemButton
              onClick={() => handleDocumentClick(item._id)}
              sx={{
                justifyContent: open ? "initial" : "center",
                p: 1,
              }}
            >
              <Tooltip title={item.name} arrow>
                <ListItemText
                  primary={item.name}
                  sx={{
                    opacity: open ? 1 : 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    p: 0,
                    m: 0,
                  }}
                />
              </Tooltip>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering ListItemButton's onClick
                  handleOpenConfirm(item._id);
                }}
                sx={{ m: 0, ml: 1, p: 0 }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <BstDeleteConfirm
        confirmOpen={confirmOpen}
        handleCloseConfirm={handleCloseConfirm}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Box>
  );
};

export default DocumentList;
