import React, { useState } from "react";
import GoogleSignIn from "./googleSignIn";
import axiosInstance from "./axiosInstance";
import { toast } from "react-toastify";
import { TextField, Typography, Container, Paper, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ToastContainer } from "react-toastify";

const Register = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return "Password must be at least 8 characters long.";
    }
    if (!hasUppercase) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowercase) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasNumber) {
      return "Password must contain at least one number.";
    }
    if (!hasSpecialChar) {
      return "Password must contain at least one special character.";
    }
    return "";
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const passwordValidationError = validatePassword(password);

    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setLoading(true);
    try {
      const response = await axiosInstance.post("auth/register", {
        email,
        password,
      });
      toast.success(response.data?.message, {
        position: "bottom-right",
        autoClose: 500,
        hideProgressBar: true,
      });
      setError("");
      setPasswordError("");
    } catch (err) {
      console.log("err", err);
      setError(err?.response?.data?.message || "Registration Failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 8, borderRadius: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <img src="/pessoa-logo.png" alt="Icon" style={{ width: 100 }} />
        </Box>

        <form onSubmit={handleRegister}>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError("");
              }}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setError("");
              }}
              required
            />
          </Box>
          <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            loading={loading}
            disabled={
              !!passwordError && !password && password !== confirmPassword
            }
          >
            Sign Up
          </LoadingButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              color: "grey.500",
              fontSize: "0.875rem",
            }}
          >
            <Box
              sx={{
                flex: 1,
                borderBottom: "1px dashed",
                borderColor: "grey.300",
              }}
            />
            <Box sx={{ mx: 2 }}>or</Box>
            <Box
              sx={{
                flex: 1,
                borderBottom: "1px dashed",
                borderColor: "grey.300",
              }}
            />
          </Box>
          <Box mt={2}>
            <GoogleSignIn />
          </Box>
          {passwordError && (
            <Typography color="error" align="center" mb={2}>
              {passwordError}
            </Typography>
          )}
          {error && (
            <Typography color="error" align="center" mb={2}>
              {error}
            </Typography>
          )}
        </form>
      </Paper>
      <Typography align="center" mt={2}>
        Already have an account? <a href="/login">Login here</a>
      </Typography>
      <ToastContainer />
    </Container>
  );
};

export default Register;
