import { createEmptySheet } from "../utils/tools";

export const companyName = "Pessoa";

export const DocTypes = {
  document: "document",
  sheet: "sheet",
  draw: "draw",
  flow: "flow",
  media: "media",
  code: "code",
  api: "api",
  table: "table",
};

export const EmptySheet = createEmptySheet(20, 20);

export const drawerWidth = 200;

export const subDocHeight = "400px";
export const scrollUnit = 400;

export const toastConfig = {
  position: "bottom-right",
  autoClose: 1000,
  progress: false,
  hideProgressBar: true,
};
