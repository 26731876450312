import { Box, TableCell } from "@mui/material";
import { styled } from "@mui/material";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const BstTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "25px",
    margin: "0px",
  },
  "& .MuiInputBase-input": {
    fontSize: "12px",
    padding: "10",
  },
});

export const BstHeaderField = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "20px",
    margin: "0px",
  },
  "& .MuiInputBase-input": {
    fontSize: "14px",
    padding: "0",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  // "& .MuiInput-underline:after": {
  //   borderBottom: "none",
  // },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
});

export const BstSourceSearchTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "30px",
    margin: "0px",
    borderRadius: "10px",
  },
  "& .MuiInputBase-input": {
    fontSize: "12px",
    padding: "10",
  },
});

export const BstDocumenSearchTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "35px",
    margin: "0px",
    borderRadius: "10px",
  },
  "& .MuiInputBase-input": {
    fontSize: "12px",
  },
});

export const BstTableTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 0,
    width: "auto",
  },
  "& .MuiInputBase-input": {
    borderRadius: 0,
    width: "auto",
  },
  "& .MuiOutlinedInput-root": {
    width: "auto",
  },
});

export const BstTableColumnTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    borderRadius: 0,
    width: "auto",
  },
  "& .MuiInputBase-input": {
    borderRadius: 0,
    width: "auto",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove border,
    },
    "&:hover fieldset": {
      border: "none", // Remove border on hover
    },
    "&.Mui-focused fieldset": {
      border: "none", // Remove border when focused
    },
  },
});

export const BstTableCell = styled(TableCell)({
  padding: "0px",
});

export const BstDatePicker = styled(DatePicker)({
  "& .MuiInputBase-root": {
    height: "30px",
    marginBottom: "10px",
  },
  "& .MuiInputBase-input": {
    fontSize: "12px",
    padding: "10",
  },
});

export const FullScreenContainer = styled(Box)(({ isFullscreen, theme }) => ({
  position: isFullscreen ? "fixed" : "relative",
  top: isFullscreen ? 0 : "auto",
  left: isFullscreen ? 0 : "auto",
  width: isFullscreen ? "100vw" : "100%",
  height: isFullscreen ? "100vh" : "auto",
  backgroundColor: theme.palette.background.paper, // Ensures the background is white and not black
  zIndex: isFullscreen ? 1300 : "auto",
  overflow: isFullscreen ? "auto" : "hidden",
  // constants
  borderRadius: isFullscreen ? "none" : "10px",
}));

export const CustomLanguageSelect = ({ language, handleLanguageChange }) => {
  return (
    <FormControl
      sx={{
        m: 1,
        ml: 5,
        p: 0,
        minWidth: 120,
        "& .MuiOutlinedInput-root": { height: 30 },
      }}
    >
      <InputLabel id="language-select-label" sx={{ fontSize: "0.8rem" }}>
        Language
      </InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={language}
        label="Language"
        onChange={handleLanguageChange}
        sx={{ height: 30, lineHeight: 1.5, fontSize: "0.8rem" }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 200, // Adjust the max height of the dropdown menu
            },
          },
        }}
      >
        <MenuItem value="javascript">JavaScript</MenuItem>
        <MenuItem value="python">Python</MenuItem>
        <MenuItem value="java">Java</MenuItem>
        <MenuItem value="c_cpp">C/C++</MenuItem>
        <MenuItem value="html">HTML</MenuItem>
        <MenuItem value="golang">Go</MenuItem>
        <MenuItem value="css">CSS</MenuItem>
        <MenuItem value="php">PHP</MenuItem>
      </Select>
    </FormControl>
  );
};
